/* eslint-disable @typescript-eslint/no-explicit-any */
import type { JSX } from 'react';
import { useMemo } from 'react';
import type { z } from 'zod';
import { Card, CardHeader, CardTitle } from '~/components/ui/card';
import ErrorBoundary from '~/components/utils/ErrorBoundaries';
import { ZodErrorHelper } from './ZodErrorHelper';

type ZodObjectOrWrapped =
  | z.ZodEffects<z.ZodObject<any, any>>
  | z.ZodObject<any, any>;

export type MdxZodEditorProps<F extends z.ZodType<any, any, any>> = {
  schema: F;
  Component: (props: z.infer<F>) => JSX.Element | null;
  name?: string;
};

export function withZodTypeSafeProps<F extends ZodObjectOrWrapped>({
  schema,
  name,
  Component,
}: MdxZodEditorProps<F>) {
  const InnerComponent = (innerProps: z.infer<F>) => {
    const safeProps = useMemo(() => {
      return schema.safeParse(innerProps);
    }, [innerProps]);

    if (safeProps.success) {
      return (
        <ErrorBoundary
          fallback={
            <Card className="not-prose border-destructive bg-destructive/10">
              <CardHeader>
                <CardTitle>Error in component {name ?? '?'}</CardTitle>
              </CardHeader>
            </Card>
          }
        >
          <Component {...safeProps.data} />
        </ErrorBoundary>
      );
    }

    const error = safeProps.error;

    return <ZodErrorHelper name={name} error={error} />;
  };

  InnerComponent.displayName = name ?? Component.name;

  return InnerComponent;
}
